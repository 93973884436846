import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../../components/graphql-error-list'
import { Layout, LanguageContext } from '../../layout/Layout'
import { FlexList } from '../../components/FlexList';
import SEO from '../../components/seo';
import HeaderPadding from '../../layout/HeaderPadding';
import { useContext } from 'react';
import { usePageTitle } from '../../hooks/usePageTitle';

export const query = graphql`
  query JobsPageQueryEn {
    allSanityJob(filter: {slug: {current: {ne: null}}}) {
      
      edges {
        node {
          _id
          title {
            nb
            en
          }
          slug {
            current
          }
      
        }
      }
    
        }
  }
`


const JobsPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  
  const lang = useContext(LanguageContext);
  usePageTitle('Jobs');
  const listData = data.allSanityJob.edges.map(({ node: { _id, title, slug: { current: slug } } }) => ({
    id: _id,
    title: title[lang],
    url: `/${lang}/job/${slug}`
  }));

  return (
    <Fragment>
      <SEO title="Jobs" />
      <HeaderPadding />
      <FlexList data={listData} />
    <HeaderPadding />
    </Fragment>
  )
}

export default JobsPage
